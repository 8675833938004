import React from 'react'
import "./style.css"

function ContactUs() {
  return (
    <>
        <div className="container contactLayout">
            <div className="contactLayout-module-border-wrap">
                <div className="contactLayout-module">
                    <div className='ContactUsTitle pb-4'>Get In Touch With Us</div>
                    <div className="row">
                        <div className="col-xl">
                            <input type="text" className='custom-input my-2' placeholder='Full Name' />
                            <input type="text" className='custom-input my-2' placeholder='Mobile Number' />
                            <select name="" className='custom-select my-2' id="">
                                <option value=""  selected disabled>What is your requirement?</option>
                                <option value="">Option 1</option>
                                <option value="">Option 2</option>
                                <option value="">Option 3</option>
                                <option value="">Option 4</option>
                            </select>
                            <input type="text" className='custom-input my-2' placeholder='What is your vision about your business?' />
                        </div>
                        <div className="col-xl">
                            <input type="text" className='custom-input my-2' placeholder='Email Address' />
                            <input type="text" className='custom-input my-2' placeholder='Website Link' />
                            <select name="" className='custom-select my-2' id="">
                                <option value=""  selected disabled>What is your budget?</option>
                                <option value="">Option 1</option>
                                <option value="">Option 2</option>
                                <option value="">Option 3</option>
                                <option value="">Option 4</option>
                            </select>
                            <select name="" className='custom-select my-2' id="">
                                <option value=""  selected disabled>How did you get to know about us?</option>
                                <option value="">Option 1</option>
                                <option value="">Option 2</option>
                                <option value="">Option 3</option>
                                <option value="">Option 4</option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <button className='contactUsBtn my-2'>Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default ContactUs