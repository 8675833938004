import React from 'react'
import PenLine from '../../../assets/images/PenLine.svg';
import "./style.css";

function PPStore(){
    
    function CustomGreenBadge(props){
        return(
            <>
                <span className='CustomBadge' style={{color:'#E7FF3C'}}>
                    {props.title}
                </span>
            </>
        );
    }


    return(
        <>
            <div id='services' style={{padding:40}}></div>
            <div className="container"   style={{marginTop:'80px',marginBottom:'130px'}}>
                <div className="row">
                    <div className="col-xl-4" style={{position:'relative',minHeight:'270px'}} >
                        <div className='Session2Title'>Our PP’s Store</div>
                        <img src={PenLine} className='Session2Penline'  alt="" />
                        <div className='Session2Description'>We blend visuals with scroll-worthy content to make your brand the talk of the fees, likes and shared—just like <span style={{color:'#E7FF3C', fontWeight:'bold'}}>Gabbar's epic lines.</span> </div>
                    </div>
                    <div className="col-xl">
                        <div className="store_gird">
                            <div className="module-border-wrap store_grid_item ">
                                <div className="module">
                                    <div className='storeLine1'><span className='storeLineBold'>Design </span>that delights</div>
                                    <div className='description'>They're so beautiful, so elegant, you'll want to keep them forever. They're made to be saved and shared.</div>
                                    <div className='block_items'>
                                        <CustomGreenBadge title="Brand Identity"/>
                                        <CustomGreenBadge title="UI/UX Design"/>
                                        <CustomGreenBadge title="Website Design"/><br />
                                        <CustomGreenBadge title="Graphics Design"/>
                                        <CustomGreenBadge title="Packaging Design"/>
                                    </div>
                                </div>
                            </div>
                            <div className="module-border-wrap store_grid_item ">
                                <div className="module">
                                    <div className='storeLine1'>Want<span className='storeLineBold'> content</span> <br /> that connects.</div>
                                    <div className='description'>That don't just resonate but truly hit home, ensuring your audience feels a genuine connection with your brand.</div>
                                    <div className='block_items'>
                                        <CustomGreenBadge title="Creative Copywiriting"/>
                                        <CustomGreenBadge title="Website Content Writing"/><br />
                                        <CustomGreenBadge title="Social media campaigns"/>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="module-border-wrap store_grid_item ">
                                <div className="module">
                                <div className='storeLine1'>Like a <span className='storeLineBold'>viral </span>sensation</div>
                                <div className='description'>We strategize to make your brand buzzworthy, shareable, and the social reel that everyone loops and talks about</div>
                                <div className='block_items'>
                                    <CustomGreenBadge title="Meme/Viral Marketing"/>
                                    <CustomGreenBadge title="Social Media Marketing"/><br />
                                    <CustomGreenBadge title="Social Media Management"/>
                                </div>
                            </div>
                            </div> */}
                            <div className="store_grid_item" style={{backgroundColor:'#E7FF3C',color:'#000'}}>
                                {/* <div>So,<br/>Let's Connect</div> */}
                                <div></div>
                                <div className='description'>Because you might-know-something, I might-know-something, and together, we can make it the talk of the town.</div>
                                <button className='btnConverse'>Let’s Converse</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PPStore