import React from 'react'
import "./style.css"
import BottomCurtain from '../../../assets/images/BottomCurtain.svg'

function Session3Carousel(){
    return(
        <>
            <div style={{position:'relative'}}>
                <div className="container"  >
                    <div className="row">
                        <div className="col-md-7 mt-4">
                            <div className="Session3BoxLayout-border-wrap">
                                <div className="Session3BoxLayoutModule">
                                    <div className='title'>Long-Term Partnership, Exceptional Results:</div>
                                    <div className='description'>We believe in building lasting relationships with quality service that evolves grows with your brand</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 mt-4">
                            <div className="Session3BoxLayout-border-wrap">
                                <div className="Session3BoxLayoutModule">
                                    <div className='title'>Speak Loud & Clear:</div>
                                    <div className='description'>Addresses consistent, impactful messaging that resonates across all platforms, ensuring your brand voice is crystal clear.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col-md-5 mt-4">
                            <div className="Session3BoxLayout-border-wrap">
                                <div className="Session3BoxLayoutModule">
                                    <div className='title'>Custom-Made Solutions:</div>
                                    <div className='description'>No one-size-fits-all here. We create strategies based on your unique brand so we meet your objectives</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7 mt-4">
                            <div className="Session3BoxLayout-border-wrap">
                                <div className="Session3BoxLayoutModule">
                                    <div className='title'>Compelling Stories, Strategic Results: </div>
                                    <div className='description'>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.It has survived not only five centuries.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='BottomCurtain'>
                    <img src={BottomCurtain} alt="" />
                </div>
            </div>

        </>
    );
}

function Session3(){
    return(
        <>
            <div id='about-us' style={{padding:20}}></div>
            <div className="container"  style={{position:'relative',marginTop:'50px',marginBottom:'100px'}}>
                <div className='Session3Back'>PEOPLE MEET PASSION HERE!</div>
                <div className='Session3Front'>Understanding your brand is central to everything we do at <span className='greenColor'>Passie Peeps</span>. Thinking <span className='greenColor'>how?</span> Here, We decode your brand’s inner workings, providing <span className='greenColor'>insights & solution</span> that’ll leave you saying. <span className='greenColor'>Wow, they get me!</span></div>
            </div>
            
            <Session3Carousel/>
        </>
    );
}

export default Session3