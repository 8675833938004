import React from 'react'
import CTAElements from "../../../assets/images/CTA-all-Element.svg"
import "./style.css"
import ArrowLetsDo from '../../../assets/LetsDoArrow.svg'

function CTASession() {
  return (
    <>
      <div className="container my-5" style={{backgroundColor:'#E7FF3C'}}>
        <div className="row">
          {/* Second column should come first on small screens */}
          <div className="col-xl CTA_block_2 py-5 order-1 order-xl-2">
            <img src={CTAElements} alt="" />
          </div>
          {/* First column should come second on small screens */}
          <div className="col-xl CTA_block_1 p-5 order-2 order-xl-1">
            <div className='CTA-Text'>
              Get a reliable partner that provides solutions to design Challenges!
            </div>
            <div className='CTA-Button'>
              <button className="letsDoBtn PassiePeepsBtn">
                Lets Do it <span className='circle'>
                  <img src={ArrowLetsDo} className='ArrowLetsDo' alt="" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CTASession


// import React from 'react'
// import CTAElements from "../../../assets/images/CTA-all-Element.svg"
// import "./style.css"
// import ArrowLetsDo from '../../../assets/LetsDoArrow.svg'

// function CTASession() {
//   return (
//     <>
//         <div className="container my-5" style={{backgroundColor:'#E7FF3C'}}>
//             <div className="row">
//                 <div className="col-xl CTA_block_1 p-5">
//                     <div className='CTA-Text'>Get a reliable partner that provides solutions to design Challenges!</div>
//                     <div className='CTA-Button'>
//                         {/* <button>Lets Do it</button> */}
//                         <button className="letsDoBtn PassiePeepsBtn">Lets Do it <span className='circle'><img src={ArrowLetsDo} className='ArrowLetsDo' alt="" /></span></button>
//                     </div>
//                 </div>
//                 <div className="col-xl CTA_block_2 py-5">
//                     <img src={CTAElements} alt="" />
//                 </div>
//             </div>
//         </div>
//     </>
//   )
// }

// export default CTASession