import React from 'react'
import "./style-footer.css"
import LINKEDIN from "../../assets/images/Social-icons/LINKEDIN - MOHIT SAVALIYA.svg"
import INSTAGRAM from "../../assets/images/Social-icons/INSTAGRAM - MOHIT SAVALIYA.svg"
import FACEBOOK from "../../assets/images/Social-icons/FACEBOOK - MOHIT SAVALIYA.svg"
import WHATSAPP from "../../assets/images/Social-icons/WHATSAPP - MOHIT SAVALIYA.svg"

function Footer() {
  return (
    <>
        <div className="container">
            <div className='Social-Media-layout mb-5'>
                <img src={LINKEDIN} alt="" />
                <img src={INSTAGRAM} alt="" />
                <img src={FACEBOOK} alt="" />
                <img src={WHATSAPP} alt="" />
            </div>

            <div className="row footer_layout mb-5">
                <div className="col-xl">
                    <div className="companyName">© 2024 Passiepeeps All Rights Reserved</div>
                </div>
                <div className="col-xl">
                <div className="DevelopedBy">Developed by <a href="https://makeitech.com/" target='_blank'>makeitech</a></div>

                    {/* <div className="MenuLayout">
                        <span>Home</span>
                        <span>About Us</span>
                        <span>Services</span>
                        <span>Our Work</span>
                    </div> */}
                </div>
            </div>

            {/* <div className='footer_layout mb-5'>
                <div className="companyName">© 2024 Passiepeeps All Rights Reserved</div>
                <div className="MenuLayout">
                    <span>Home</span>
                    <span>About Us</span>
                    <span>Services</span>
                    <span>Our Work</span>
                </div>
            </div> */}
        </div>
    </>
  )
}

export default Footer