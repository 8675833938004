import React from 'react'
import Header from '../Components/Header'
import "./style.css";
import blackground_curtain from '../../assets/blackground_curtain.svg'
import HeroSession from './HeroSession/HeroSession';
import PPStore from './PPStore/PPStore';
import Session3 from './Session3/Session3';
import Session4 from './Session4/Session4';
import CTASession from './CTASession/CTASession';
import ContactUs from './ContactUsSession/ContactUs';
import Footer from '../Components/Footer';




function LandingPage() {
  return (
    <>
        <Header/>
        <HeroSession/>
        <PPStore/>
        <Session3/>
        <Session4/>
        <CTASession/>
        <ContactUs/>
        <Footer/>
        

        <div className="heroSessionBackground3">
            <img src={blackground_curtain} alt="" />
        </div>
    </>
  )
}

export default LandingPage