import React from 'react'
import './style.css'
import pen from '../../../assets/PenheroPage.svg'
import Arrow from '../../../assets/ArrowHeroPage.svg'
import FramerMotionExampleImage from '../../../assets/framerMotionExample.png'

import { motion, useScroll, useTransform } from "framer-motion";
import { AnimationBody, ImageContainer } from "./animeStyle";
import $ from 'jquery';
import { ClientHandleAnimation } from './Animations/ClientHandleAnimation'
import { ProjectsCompletedAnimation } from './Animations/ProjectsCompletedAnimation'

function HeroSession(){
    const { scrollYProgress } = useScroll();
    const x1 = useTransform(scrollYProgress, [0, 1], [10, -1000]);

    function CustomBadge(props){
        return(
            <>
                <span className='CustomBadge'>
                    {props.roles}
                </span>
            </>
        );
    }


    setTimeout(function(){

    $('.counters').each(function () {
        var $this = $(this),
            countTo = $this.attr('data-count');

        function formatIndianNumber(number) {
            var parts = number.toString().split('.');
            var integerPart = parts[0];
            var decimalPart = parts[1] ? '.' + parts[1] : '';

            var lastThree = integerPart.slice(-3);
            var otherParts = integerPart.slice(0, -3);

            if (otherParts !== '') {
                lastThree = ',' + lastThree;
            }

            var result = '';
            while (otherParts.length > 2) {
                result = ',' + otherParts.slice(-2) + result;
                otherParts = otherParts.slice(0, -2);
            }

            result = otherParts + result + lastThree;
            return result + decimalPart;
        }

        $({
            countNum: $this.text()
        }).animate({
            countNum: countTo
        }, {
            duration: 500,
            easing: 'linear',
            step: function () {
                $this.text(formatIndianNumber(Math.floor(this.countNum)));
            },
            complete: function () {
                $this.text(formatIndianNumber(this.countNum));
            }
        });
    });
    },100)




    function ClientHandleFunc(){
        return(
            <div className='clientHandle'>
                <div className="counters_layout counters-style" style={{display:'flex'}}><div className="counters" data-count="150"></div>+</div>
                <span>Client Handle</span>
            </div>
        )
    }

    function ProjectsCompletedFunc(){
        return(
            <>
                <div className='projectsCompleted'>
                    <div className="counters_layout counters-style" style={{display:'flex'}}><div className="counters" data-count="100"></div>+</div>
                    <span>Projects Completed</span>
                </div>
            </>
        )
    }

    function HeroParagraphContent(){
        return(
            <>
                <div className='paragraphContent'>
                    <span style={{color:'#E7FF3C'}}>Passion-driven, purpose-driven.</span> We (Peeps) helps you connect with your audience through compelling visuals and engaging content. Because it's time to stand out and be remembered.
                </div>
            </>
        );
    }


    return(
        <>
            <div className="container">
                <div className="heroSessionMainLayout">
                    <div className="row" style={{height:'80vh'}}>
                        <div className="col-md"></div>
                        <div className="col-md-12">
                            <div className='heroSessionMain'>
                                <div className='Herolabel'>
                                    <span>Design & Content Production Company for </span>
                                    <div style={{display:'inline-block'}}>
                                        <CustomBadge roles="UI/UX"/>
                                        <CustomBadge roles="UI/UX"/>
                                        <CustomBadge roles="UI/UX"/>
                                    </div>
                                </div>

                                <div className='HeroPageMainBodyLayout'>
                                    <div className='HeroPageMainBodyText'>
                                    {/* clientHandleFunc() projectsCompletedFunc() */}
                                        
                                        {/* <ClientHandleAnimation /> */}
                                        <h1 style={{position:'relative'}}>We're Not Just <ClientHandleFunc/></h1>
                                        <h1>Creative, We're Your</h1>
                                        <h1 style={{position:'relative'}}><ProjectsCompletedFunc/>Brand's Visual Voice.</h1>
                                        <img className='penSk1' src={pen} alt="" />
                                    </div>

                                    <div className='HeroParagraphContentPhone'>
                                        <HeroParagraphContent/>
                                    </div>
                                    {/* <ProjectsCompletedAnimation/> */}
                                    
                                    <div className='actionBtn'>
                                        <button className='CustomGreenBtn PassiePeepsBtn'>Hire Us</button>
                                        <button className='CustomBlackBtn PassiePeepsBtn'>Our Work</button>
                                    </div>


                                    {/* Arrow mark */}
                                    <div className='arrowMark'>
                                        <img src={Arrow} alt="" />
                                        <HeroParagraphContent/>
                                    </div>

                                    

                                    {/* client handle */}



                                    {/* Projects Completed */}

                                </div>
                            </div>
                        </div>
                        <div className="col-md"></div>
                    </div>
                </div>
            </div>

            <div className="marquee_label_layout">
                <AnimationBody>
                    <ImageContainer style={{ x: x1 }}>
                        <img src={FramerMotionExampleImage} className='framerImage' alt="" />
                        <img src={FramerMotionExampleImage} className='framerImage' alt="" />
                        <img src={FramerMotionExampleImage} className='framerImage' alt="" />
                        <img src={FramerMotionExampleImage} className='framerImage' alt="" />
                        <img src={FramerMotionExampleImage} className='framerImage' alt="" />
                        <img src={FramerMotionExampleImage} className='framerImage' alt="" />
                        <img src={FramerMotionExampleImage} className='framerImage' alt="" />
                        <img src={FramerMotionExampleImage} className='framerImage' alt="" />
                        <img src={FramerMotionExampleImage} className='framerImage' alt="" />
                        <img src={FramerMotionExampleImage} className='framerImage' alt="" />
                        <img src={FramerMotionExampleImage} className='framerImage' alt="" />
                        <img src={FramerMotionExampleImage} className='framerImage' alt="" />
                        <img src={FramerMotionExampleImage} className='framerImage' alt="" />
                        <img src={FramerMotionExampleImage} className='framerImage' alt="" />
                        <img src={FramerMotionExampleImage} className='framerImage' alt="" />
                        <img src={FramerMotionExampleImage} className='framerImage' alt="" />
                        <img src={FramerMotionExampleImage} className='framerImage' alt="" />
                        <img src={FramerMotionExampleImage} className='framerImage' alt="" />
                        <img src={FramerMotionExampleImage} className='framerImage' alt="" />
                        <img src={FramerMotionExampleImage} className='framerImage' alt="" />
                        <img src={FramerMotionExampleImage} className='framerImage' alt="" />
                    </ImageContainer>
                </AnimationBody>
            </div>



            {/* <div className="marquee_label_layout">
                <AnimationBody>
                    <Title style={{ x: x1 }}>
                    UI/UX <span className="wodo_yellow">•</span> LOGOS{" "}
                    <span className="wodo_yellow">•</span> WEBSITES{" "}
                    <span className="wodo_yellow">•</span> BRAND STRATEGY{" "}
                    <span className="wodo_yellow">•</span> CREATIVE CONTENT{" "}
                    <span className="wodo_yellow">•</span> UI/UX{" "}
                    <span className="wodo_yellow">•</span> LOGOS{" "}
                    <span className="wodo_yellow">•</span> WEBSITES{" "}
                    <span className="wodo_yellow">•</span> BRAND STRATEGY{" "}
                    <span className="wodo_yellow">•</span> CREATIVE CONTENT
                    
                    <img src={FramerMotionExampleImage} width={100} alt="" />
                    <img src={FramerMotionExampleImage} width={100} alt="" />
                    <img src={FramerMotionExampleImage} width={100} alt="" />
                    <img src={FramerMotionExampleImage} width={100} alt="" />
                    <img src={FramerMotionExampleImage} width={100} alt="" />
                    <img src={FramerMotionExampleImage} width={100} alt="" />
                    <img src={FramerMotionExampleImage} width={100} alt="" />
                    </Title>
                </AnimationBody>
            </div> */}

            {/* <div className='heroSessionBackground'>
                <img src={curtainLeft} alt="" />
                <img src={curtainRight} alt="" />
            </div> */}

        </>
    );

}

export default HeroSession