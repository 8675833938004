import React from 'react'
import LOGO from '../../assets/logo_01.svg'
import "./style.css";
import ArrowLetsDo from '../../assets/LetsDoArrow.svg'



function Header() {


  return (
    <>
        <div className="headerLayout">
            <div className="container">
                <nav className="navbar navbar-expand-xl">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="#">
                            <img src={LOGO} alt="" />
                        </a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            </ul>
                            <div className='navbar-menu me-auto mb-2 mb-lg-0'>
                                <ul className="navbar-nav  me-auto mb-2 mb-lg-0">
                                    <li className="nav-item">
                                        <a className="nav-link active" aria-current="page" href="#">Home</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#about-us">About Us</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#services">Services</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#">Our Work</a>
                                    </li>
                                </ul>
                            </div>
                            
                            <form className="d-flex " role="search">
                                <button className="letsDoBtn PassiePeepsBtn">Lets Do it <span className='circle'><img src={ArrowLetsDo} id='ArrowLetsDo1' className="ArrowLetsDo" alt="" /></span></button>
                            </form>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </>
  )
}

export default Header