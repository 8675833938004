import React from 'react'
import { 
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import LandingPage from './app/LandingPage/LandingPage';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<LandingPage/>}/>
          {/* <Route path='*' element={<NotFound/>}/> */}
        </Routes>
      </Router>
    </>
  )
}

export default App
