import styled from "styled-components";
import { motion } from "framer-motion";

export const AnimationBody = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  color: white;
`;

export const ImageContainer = styled(motion.div)`
  display: flex;
  white-space: nowrap;
  margin-top: auto;
  transition: all 0.1s ease-in-out;
  gap:80px;
`;